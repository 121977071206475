/* - - - - - - - - - - - - - - - - - - - - -

Title : Lightbox CSS
Author : Kevin Hale
URL : http://particletree.com/features/lightbox-gone-wild/

Created : January 13, 2006
Modified : February 1, 2006

- - - - - - - - - - - - - - - - - - - - - */

#lightbox{
	display:none;
	position: absolute;
	top:50%;
	left:50%;
	z-index:9999;
	width:500px;
	height:500px;
	margin:-250px 0 0 -250px;
	background:#fff;
	text-align:center;
  vertical-align: 50%;
	border: 2px solid black;
}

#lightbox[id]{
	position:fixed;
}

#overlay{
	display:none;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:5000;
	background-color:#000;
	-moz-opacity: 0.65;
	opacity:.65;
	filter: alpha(opacity=65);
}
#overlay[id]{
	position:fixed;
}

#lightbox.done #lbLoadMessage{
	display:none;
}
#lightbox.done #lbContent{
	display:block;
	width: 480px;
	height: 480px;
	border: 10px solid white;
}
#lightbox.loading #lbContent{
	display:none;
}
#lightbox.loading #lbLoadMessage{
	display:block;
}

#lightbox.done img{
  vertical-align: bottom;

}